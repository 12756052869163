import * as yup from 'yup';
import {
  isValidCheckSum,
  nonRsaPassportRegex,
  rsaIdRegex
} from '../../../constants';

export const PersonalDetailsSchema = yup.object().shape({
  idNumber: yup.string().when('idType', {
    is: 'id',
    then: () => yup.string().matches(rsaIdRegex, 'SA ID Number is not valid')
      .test('idNumber', 'SA ID Number is not valid', (item) => isValidCheckSum(item))
      .required('SA ID Number is a required field'),
    otherwise: () => yup.string().nullable().optional()
  }),
  passportNumber: yup.string().when('idType',
    {
      is: 'pp',
      then: () => yup.string().matches(nonRsaPassportRegex, 'Passport Number is not valid').required('Passport number is a required field'),
      otherwise: () => yup.string().nullable().optional()
    }
  ),
  passportCountry: yup.string().when('idType', {
    is: 'pp',
    then: () => yup.string()
      .required('Passport Country is required'),
    otherwise: () => yup.string().nullable().optional()
  }),

  whenMovedIn: yup
    .date()
    .required('When did you move date is a required field')
    .max(new Date(), 'When did you move date should be in past'),

  flatUnitNumber: yup.string().when('buildingType', {
    is: 'Flat/Apartment',
    then: () => yup.number()
      .typeError('Flat/Unit/Floor must be a number')
      .positive('Flat/Unit/Floor must be positive')
      .integer('Flat/Unit/Floor must be a whole number')
      .nullable(),
    otherwise: () => yup.string().nullable().optional()
  }),
  townUnitNumber: yup.string().when('buildingType',
    {
      is: 'Townhouse/Security Estate',
      then: () => yup.number()
        .typeError('Unit or House number must be a number')
        .positive('Unit or House number must be positive')
        .integer('Unit or House number must be a whole number')
        .nullable(),
      otherwise: () => yup.string().nullable().optional()
    }
  ),
  officeUnitNumber: yup.string().when('buildingType',
    {
      is: 'Office',
      then: () => yup.number()
        .typeError('Unit or Suite number must be a number')
        .positive('Unit or Suite number must be positive')
        .integer('Unit or Suite number must be a whole number')
        .nullable(),
      otherwise: () => yup.string().nullable().optional()
    }
  ),
  houseUnitNumber: yup.string().when('buildingType',
    {
      is: 'House',
      then: () => yup.number()
        .typeError('Unit or House number must be a number')
        .positive('Unit or House number must be positive')
        .integer('Unit or House number must be a whole number')
        .nullable(),
      otherwise: () => yup.string().nullable().optional()
    }
  ),

  flatBuilding: yup.string().when('buildingType', {
    is: 'Flat/Apartment',
    then: () => yup.string()
      .required('Building Name is required'),
    otherwise: () => yup.string().nullable().optional()
  }),
  townBuilding: yup.string().when('buildingType',
    {
      is: 'Townhouse/Security Estate',
      then: () => yup.string()
        .required('Estate Name is required'),
      otherwise: () => yup.string().nullable().optional()
    }
  ),
  officeBuilding: yup.string().when('buildingType',
    {
      is: 'Office',
      then: () => yup.string()
        .required('Building or Office park name is required'),
      otherwise: () => yup.string().nullable().optional()
    }
  ),

  houseStreet: yup.string().when('buildingType', {
    is: 'House',
    then: () => yup.string()
      .required('Street Name is required'),
    otherwise: () => yup.string().nullable().optional()
  }),
  flatStreet: yup.string().when('buildingType', {
    is: 'Flat/Apartment',
    then: () => yup.string()
      .required('Address is required'),
    otherwise: () => yup.string().nullable().optional()
  }),
  townStreet: yup.string().when('buildingType',
    {
      is: 'Townhouse/Security Estate',
      then: () => yup.string()
        .required('Street Name is required'),
      otherwise: () => yup.string().nullable().optional()
    }
  ),
  officeStreet: yup.string().when('buildingType',
    {
      is: 'Office',
      then: () => yup.string()
        .required('Address is required'),
      otherwise: () => yup.string().nullable().optional()
    }
  ),

  suburb: yup.string()
    .required('Suburb is required'),

  title: yup.string()
    .required('Title is required'),

  positionInCompany: yup.string()
    .required('Position in Company is required'),

  residentialStatus: yup.string()
    .required('Residential Status is required'),

  idType: yup.string()
    .required('Id Type is required'),

  provinces: yup.string()
    .required('Provinces is required'),

  buildingType: yup.string()
    .required('Building Type is required'),

  postalCode: yup.string()
    .matches(/^\d{4,6}$/, 'Postal Code must be 4-6 digits')
    .required('Postal Code is required')

});
